import React from 'react'
import { Chart } from "react-google-charts";

const Bar = ({ data, vaxis, haxis}) =>{
  const options = {
    legend: {
        position: 'none',
        
    },
    axes: {
        x: {
          0: { side: 'top' },
        },
    },
    bars: 'horizontal',
    title: 'Bar chart',
    vAxis: {
      title: vaxis
    },
     hAxis: {
       title: haxis
     },
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    }
  };

  return (
    
  <div >
    <Chart
  width={'100%'}
  height={'400px'}
  chartType="Bar"
  loader={<div>Loading Chart</div>}
  data={[
    ['Year', 'Sales'],
    ["Dirty Data",	                            49.4],
    ["Lack of data science talent",	            41.6],
    ["Lack of management/financial support",	37.2],
    ["Lack of clear question to answer",	    30.4],
    ["Data unavailable or difficult to access",	30.2],
    ["Results not used by decision makers",	    24.3],
    ["Explaining data science to others",	    22.0],
    ["Privacy issues",                          19.8],  
    ["Lack of domain expert input",	            19.6],
    ["Can't afford data science team",          17.8],
    ["Multiple ad-hoc environments",            17.5],
    ["Limitations of tools",                    16.5],
    ["Need to coordinate with IT",              16.3],
    ["Expectations of project impact",          15.8],
    ["Integrating findings into decisions",	    13.6]    
  ]}
  options={options}
  rootProps={{ 'data-testid': '1' }}
/>
      
  </div>

)
          }
export default Bar

