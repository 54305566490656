import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CompanyProfile from './companyProfile'

class FeaturedCompanies extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      direction: 'left',
      details: '',
    }
  }
  showDetails = () => {
    return this.state.details
  }
  handleProfileClick(profile, j, company_text) {
    if (profile.state.active !== j) {
      if (j % 2) {
        this.setState({ direction: 'left' })
      } else {
        this.setState({ direction: 'right' })
      }

      profile.setState({ active: j })
      this.setState({ details: company_text, active: true })
    } else {
      profile.setState({ active: null })
      this.setState({ active: false })
    }
  }

  // this.state.details && onShowDetails()

  render() {
    let classes = 'company-details '
    classes += this.state.direction + ' '
    classes += this.state.active ? 'active' : 'inactive'

    return (
      <div className="row">
        <div className="container container-960">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <CompanyProfile
                  layout={this.props.layout}
                  onProfileClick={(profile, j, company_text) =>
                    this.handleProfileClick(profile, j, company_text)
                  }
                />
              </div>
              <div
                className={classes}
                dangerouslySetInnerHTML={{
                  __html: this.showDetails(),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
CompanyProfile.propTypes = {
  //layout: PropTypes.object.isRequired,
}
export default FeaturedCompanies
