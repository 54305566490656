import React, { Component } from 'react'
import PropTypes from 'prop-types'

class CompanyProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: '',
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.layout.company_profiles.map((profile, j) => (
          <div
            className={
              this.state.active === j
                ? 'col-md-6 company-profile active'
                : 'col-md-6 company-profile'
            }
            key={j}
            onClick={() =>
              this.props.onProfileClick(this, j, profile.company_text)
            }
          >
            <span className="openCloseIcon" />
            <div className="company-profile-cover">
              <div className="company-profile-cover-inner">
                <img
                  src={profile.company_logo.source_url}
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    )
  }
}

CompanyProfile.propTypes = {
  //profile: PropTypes.object.isRequired,
}

export default CompanyProfile
