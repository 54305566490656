import React from 'react'
import { Chart } from 'react-google-charts'

const Column = ({ data, vaxis, haxis }) => {
  const options = {
    legend: {
      position: 'top',
      alignment: 'center',
      textStyle: {
        bold: true,
      },
    },
    vAxis: {
      title: vaxis,
    },
    hAxis: {
      title: haxis,
      format: 'Y',
      ticks: [
        new Date(2012, 1),
        new Date(2013, 1),
        new Date(2014, 1),
        new Date(2015, 1),
        new Date(2016, 1),
      ],
    },
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    },
  }

  return (
    <div>
      <Chart
        width={'100%'}
        height={'400px'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={options}
        rootProps={{ 'data-testid': '4' }}
      />
    </div>
  )
}

export default Column
