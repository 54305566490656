import React from 'react'
import { Chart } from 'react-google-charts'
import PropTypes from 'prop-types'

const Column = ({ data, vaxis, haxis }) => {
  const options = {
    legend: {
      position: 'top',
      alignment: 'center',
      textStyle: {
        bold: true,
      },
    },
    vAxis: {
      title: vaxis,
    },
    hAxis: {
      title: haxis,
    },
    animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
    },
  }

  return (
    <div>
      <Chart
        chartType="ColumnChart"
        options={options}
        data={data}
        width="100%"
        height="400px"
      />
    </div>
  )
}

Column.propTypes = {
  data: PropTypes.array.isRequired,
  vaxis: PropTypes.string.isRequired,
  haxis: PropTypes.string.isRequired,
}

export default Column
